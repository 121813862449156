<script setup>
import { ref, reactive, watch, computed, onMounted } from 'vue'
import { EaseChatClient } from '@/IM/initwebsdk'
import { handleSDKErrorNotifi } from '@/utils/handleSomeData'
import { captchaImage, loginCustom, getImToken, getUserInfo } from '@/api/custom'
import { useStore } from 'vuex'
import { usePlayRing } from '@/hooks'
import { ElMessage } from 'element-plus'
const store = useStore()
const loginValue = reactive({
  phoneNumber: '',
  smsCode: '',
  code: '',
  uuid: ''
})
const state = reactive({
  codeImg: ''
})
const buttonLoading = ref(false)
//根据登陆初始化一部分状态
const loginState = computed(() => store.state.loginState)
watch(loginState, (newVal) => {
  if (newVal) {
    buttonLoading.value = false
    loginValue.phoneNumber = ''
    loginValue.smsCode = ''
  }
})
const rules = reactive({
  phoneNumber: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    {
      pattern: /^1[3-9]\d{9}$/,
      message: '请输入正确的手机号',
      trigger: ['blur', 'change']
    }
  ],
  smsCode: [
    {
      required: true,
      message: '请输入短信验证码',
      trigger: ['blur', 'change']
    }
  ]
})
onMounted(() => {
  // localStorage.clear() EASEIM_loginUser
  localStorage.removeItem('customToken')
  localStorage.removeItem('EASEIM_loginUser')
  getImg()
})
const getImg = () => {
  captchaImage().then(res => {
    ////console.log("%c Line:45 🍷 res", "color:#3f7cff", res);
    state.codeImg = "data:image/gif;base64," + res.img;
    loginValue.uuid = res.uuid
  })
}
//登陆接口调用
const loginIM = async () => {
  const { clickRing } = usePlayRing()
  clickRing()
  buttonLoading.value = true
  // 先登录客户端服务器
  loginCustom(loginValue).then(async res => {
    // 将客户端token保存在本地
    window.localStorage.setItem('customToken', JSON.stringify(res.token))
    if (res.code == 200) {
      // 获取IM token
      getImToken().then(async res2 => {
        // ////console.log("%c Line:70 🍒 res2", "color:#ffdd4d", res2);
        try {
          // 获取个人资料
          let userInfos = await getUserInfo()
          window.localStorage.setItem(`customUserInfo`, JSON.stringify(userInfos.data))
          // 用token登录IM
          try {
            let { accessToken } = await EaseChatClient.open({
              user: userInfos.data.hxusername.toLowerCase(),
              // pwd: userInfos.data.userName,
              accessToken: res2.msg
            });
            window.localStorage.setItem(`EASEIM_loginUser`, JSON.stringify({ user: userInfos.data.hxusername, accessToken: accessToken }))
            
          } catch (error) {
            ////console.log('>>>>登陆失败', error);
            const { data: { extraInfo } } = error
            handleSDKErrorNotifi(error.type, extraInfo.errDesc);
          }
          finally {
            buttonLoading.value = false;
          }
        }
        catch (er) {
          ////console.log("%c Line:93 🍧 er", "color:#7f2b82", er);

        }
      })
    } else {
      getImg()
      buttonLoading.value = false;
      ElMessage({
        type: 'error',
        message: res.msg,
        center: true
      })
    }
  }).catch(err => {
    getImg()
    buttonLoading.value = false;
    let str = err.msg
    ElMessage({
      type: 'error',
      message: str,
      center: true
    })
  })
}
</script>

<template>
  <el-form :model="loginValue" :rules="rules">
    <el-form-item prop="username">
      <el-input class="login_input_style" v-model="loginValue.username" placeholder="账号" clearable />
    </el-form-item>
    <el-form-item prop="password">
      <el-input class="login_input_style" type="password" v-model="loginValue.password" placeholder="密码" clearable />
    </el-form-item>
    <el-form-item label="" prop="code">
      <el-input class="login_input_style" v-model="loginValue.code" placeholder="请输入验证码" style="width: 250px;">

      </el-input>
      <img v-if="state.codeImg" :src="state.codeImg" alt=""
        style="height: 40px;width: 100px;vertical-align: top;margin-left: 20px;cursor: pointer;" @click="getImg()">
    </el-form-item>
    <el-form-item>
      <div class="function_button_box">
        <el-button v-if="loginValue.username && loginValue.password && loginValue.code" class="haveValueBtn"
          :loading="buttonLoading" @click="loginIM">登录</el-button>
        <el-button v-else class="notValueBtn">登录</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<style lang="scss" scoped>
.login_input_style {
  margin: 10px 0;
  width: 400px;
  height: 50px;
  padding: 0 16px;
}

::v-deep .el-input__inner {
  padding: 0 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.75px;
  color: #3a3a3a;

  &::placeholder {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    letter-spacing: 1.75px;
    color: #cccccc;
  }
}

::v-deep .el-input__suffix-inner {
  font-size: 20px;
  margin-right: 15px;
}

::v-deep .el-form-item__error {
  margin-left: 16px;
}

::v-deep .el-input-group__append {
  background: linear-gradient(90deg, #04aef0 0%, #5a5dd0 100%);
  width: 60px;
  color: #fff;
  border: none;
  font-weight: 400;

  button {
    font-weight: 300;
  }
}

.login_text {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: right;

  .login_text_isuserid {
    display: inline-block;
    // width: 100px;
    color: #f9f9f9;
  }

  .login_text_tologin {
    margin-right: 20px;
    width: 80px;
    color: #05b5f1;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.function_button_box {
  margin-top: 10px;
  width: 400px;

  button {
    margin: 10px;
    width: 380px;
    height: 50px;
    border-radius: 57px;
  }

  .haveValueBtn {
    background: linear-gradient(90deg, #04aef0 0%, #5a5dd0 100%);
    border: none;
    font-weight: 300;
    font-size: 17px;
    color: #f4f4f4;

    &:active {
      background: linear-gradient(90deg, #0b83b2 0%, #363df4 100%);
    }
  }

  .notValueBtn {
    border: none;
    font-weight: 300;
    font-size: 17px;
    background: #000000;
    mix-blend-mode: normal;
    opacity: 0.3;
    color: #ffffff;
    cursor: not-allowed;
  }
}
</style>
