
import _ from 'lodash'
import {getQuickWords} from "@/api/custom.js"
const QuickWords = {
    state: {
        quickwordList: [],
        inputQuickWord:'',

    },
    mutations: {
        SET_INPUT_QUICK_WORD: (state, payload) => {
            state.inputQuickWord = payload
        },
        SET_QUICK_WORD_LIST: (state, payload) => {
            state.quickwordList = payload
        }
    },
    actions: {
        // 获取快捷用语
        getQuickWordsList: async ({ commit }) => {
            try {
                const { data } = await getQuickWords()
                if(data){
                  commit('SET_QUICK_WORD_LIST', data)
                }else{
                  commit('SET_QUICK_WORD_LIST', [])
                }
                
            } catch (error) {
              commit('SET_QUICK_WORD_LIST', [])
            }
        }
    }
}

export default QuickWords
